var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          type: "horizon",
          dense: true,
          tabItems: _vm.tabItems,
          inlineLabel: true,
          height: _vm.tabHeight,
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: _vm.popupParam,
                    educationInfo: _vm.educationInfo,
                  },
                  on: {
                    "update:educationInfo": function ($event) {
                      _vm.educationInfo = $event
                    },
                    "update:education-info": function ($event) {
                      _vm.educationInfo = $event
                    },
                    changeStatus: _vm.changeStatus,
                    closePopup: _vm.closePopup,
                    getDetail: _vm.getDetail,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }